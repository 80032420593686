import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  Grid,
  IconButton,
  Button,
  Select,
  MenuItem,
  Divider,
  Paper,
  Stack,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { getCart, addToCart } from '../utils/cartUtils';

interface CartItem {
  id: string;
  title: string;
  quantity: number;
  price: number;
  imageUrl: string;
}

const CartPage = () => {
  const [cartItems, setCartItems] = useState<CartItem[]>([]);

  useEffect(() => {
    const loadCart = () => {
      const cart = getCart();
      setCartItems(cart.items);
    };

    loadCart();
    window.addEventListener('cartUpdated', loadCart);
    return () => window.removeEventListener('cartUpdated', loadCart);
  }, []);

  const updateQuantity = (item: CartItem, newQuantity: number) => {
    addToCart({ ...item, quantity: newQuantity - item.quantity });
  };

  const removeItem = (itemId: string) => {
    const cart = getCart();
    cart.items = cart.items.filter(item => item.id !== itemId);
    localStorage.setItem('cart', JSON.stringify(cart));
    window.dispatchEvent(new CustomEvent('cartUpdated'));
  };

  const subtotal = cartItems.reduce((sum, item) => sum + (item.price * item.quantity), 0);
  const shipping = 100; // Fixed shipping cost of ₹99
  const total = subtotal + shipping;

  if (cartItems.length === 0) {
    return (
      <Container sx={{ py: 8, minHeight: '80vh' }}>
        <Typography variant="h5" textAlign="center" gutterBottom>
          Your cart is empty
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Button variant="contained" href="/" sx={{ 
            backgroundColor: 'black',
            color: 'white',
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.8)' }
          }}>
            Continue Shopping
          </Button>
        </Box>
      </Container>
    );
  }

  return (
    <Container sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
        Shopping Cart
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Paper elevation={0} sx={{ border: '1px solid #e0e0e0', borderRadius: 2, p: 2 }}>
            {cartItems.map((item) => (
              <Box key={item.id}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={4} sm={3}>
                    <Box 
                      component="a" 
                      href={`/product/${item.id}`} 
                      sx={{ 
                        cursor: 'pointer',
                        textDecoration: 'none',
                        display: 'block'
                      }}
                    >
                      <img 
                        src={item.imageUrl} 
                        alt={item.title}
                        style={{ width: '100%', borderRadius: 8 }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={8} sm={4}>
                    <Box 
                      component="a" 
                      href={`/product/${item.id}`} 
                      sx={{ 
                        cursor: 'pointer',
                        textDecoration: 'none',
                        color: 'inherit'
                      }}
                    >
                      <Typography variant="subtitle1">{item.title}</Typography>
                      <Typography variant="body2" color="text.secondary">
                        ₹{item.price.toFixed(2)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Select
                      value={item.quantity}
                      onChange={(e) => updateQuantity(item, Number(e.target.value))}
                      size="small"
                      sx={{ minWidth: 80 }}
                    >
                      {[...Array(10)].map((_, i) => (
                        <MenuItem key={i + 1} value={i + 1}>
                          {i + 1}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <Stack 
                      direction="row" 
                      spacing={1} 
                      alignItems="center"
                      justifyContent={{ xs: 'flex-end', sm: 'flex-start' }}
                    >
                      <Typography variant="subtitle1">
                        ₹{(item.price * item.quantity).toFixed(2)}
                      </Typography>
                      <IconButton 
                        onClick={() => removeItem(item.id)}
                        size="small"
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </Stack>
                  </Grid>
                </Grid>
                <Divider sx={{ my: 2 }} />
              </Box>
            ))}
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper elevation={0} sx={{ border: '1px solid #e0e0e0', borderRadius: 2, p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Order Summary
            </Typography>
            <Box sx={{ my: 2 }}>
              <Stack spacing={2}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography color="text.secondary">Subtotal</Typography>
                  <Typography>₹{subtotal.toFixed(2)}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography color="text.secondary">Shipping</Typography>
                  <Typography>₹{shipping.toFixed(2)}</Typography>
                </Box>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="subtitle1">Total</Typography>
                  <Typography variant="subtitle1" fontWeight="bold">
                    ₹{total.toFixed(2)}
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <Button 
              fullWidth 
              variant="contained" 
              size="large"
              sx={{ 
                mt: 2,
                backgroundColor: 'black',
                color: 'white',
                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.8)' }
              }}
            >
              Checkout
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CartPage; 