interface CartItem {
  id: string;
  title: string;
  quantity: number;
  price: number;
  imageUrl: string;
}

interface Cart {
  items: CartItem[];
}

export const getCart = (): Cart => {
  const cartString = localStorage.getItem('cart');
  return cartString ? JSON.parse(cartString) : { items: [] };
};

export const addToCart = (item: CartItem): void => {
  const cart = getCart();
  const existingItemIndex = cart.items.findIndex(i => i.id === item.id);

  if (existingItemIndex >= 0) {
    // Item exists, update quantity
    cart.items[existingItemIndex].quantity += item.quantity;
  } else {
    // New item, add to cart
    cart.items.push(item);
  }

  localStorage.setItem('cart', JSON.stringify(cart));
  // Dispatch a custom event to notify components of cart updates
  window.dispatchEvent(new CustomEvent('cartUpdated'));
};

export const getCartItemsCount = (): number => {
  const cart = getCart();
  return cart.items.reduce((total, item) => total + item.quantity, 0);
}; 