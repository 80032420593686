import React, { useEffect, useState } from "react";
import { 
  Button, 
  Container, 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  CardMedia, 
  CircularProgress,
  Box
} from "@mui/material";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { collection, getDocs } from "firebase/firestore";
import { db } from "../config/firebase";
import { useNavigate } from "react-router-dom";
import { addToCart } from "../utils/cartUtils";

interface Product {
  id: string;
  title: string;
  description: string;
  quantity: number;
  price: number;
  photos: string[];
  videos: string[];
}

const ProductsPage = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "products"));
        const productsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Product[];
        setProducts(productsData);
      } catch (error) {
        console.error("Error loading products:", error);
      } finally {
        setLoading(false);
      }
    };

    loadProducts();
  }, []);

  if (loading) {
    return (
      <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "80vh" }}>
        <CircularProgress />
      </Container>
    );
  }

  const handleAddToCart = (product: Product) => {
    addToCart({
      id: product.id,
      title: product.title,
      quantity: 1,
      price: product.price,
      imageUrl: product.photos[0]
    });
  };

  return (
    <Container sx={{ py: 2 }}>
      <Grid container spacing={2}>
        {products.map((product) => (
          <Grid item key={product.id} xs={6}>
            <Card 
              sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                background: 'white',
                border: '1px solid #e0e0e0',
                borderRadius: 2,
                position: 'relative',
                transition: 'transform 0.2s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-5px)',
                }
              }}
            >
              <Box sx={{ position: 'relative' }}>
                <CardMedia
                  component="img"
                  height="400"
                  image={product.photos[0]}
                  alt={product.title}
                  sx={{ 
                    cursor: 'pointer',
                    objectFit: 'cover',
                  }}
                  onClick={() => navigate(`/product/${product.id}`)}
                />
              </Box>
              <CardContent sx={{ flexGrow: 1, px: 2, pt: 2, pb: '8px' }}>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                  {product.title}
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                  ₹{product.price.toFixed(2)}
                </Typography>
                <Button 
                  variant="contained"
                  fullWidth
                  startIcon={<AddShoppingCartIcon />}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAddToCart(product);
                  }}
                  sx={{
                    backgroundColor: 'white',
                    color: 'black',
                    '&:hover': {
                      backgroundColor: 'black',
                      color: 'white'
                    }
                  }}
                >
                  Quick Add
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ProductsPage; 