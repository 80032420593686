import React, { useState, useEffect } from "react";
import { 
  Container, 
  Typography, 
  Button, 
  Paper, 
  Box,
  CircularProgress,
  Avatar,
  Divider,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
} from "@mui/material";
import { Google as GoogleIcon, Add as AddIcon, Edit as EditIcon } from "@mui/icons-material";
import { signInWithPopup, onAuthStateChanged, User } from "firebase/auth";
import { auth, googleProvider, db, storage } from "../config/firebase";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, addDoc, getDocs } from "firebase/firestore";

interface Product {
  id: string;
  title: string;
  description: string;
  quantity: number;
  price: number;
  photos: string[];
  videos: string[];
}

const AdminPage = () => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [profileOpen, setProfileOpen] = useState(false);
  const [productDialogOpen, setProductDialogOpen] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      if (user) {
        try {
          const adminDoc = await getDoc(doc(db, "admins", user.uid));
          setIsAdmin(adminDoc.exists());
          setError(null);
        } catch (error) {
          console.error("Error checking admin status:", error);
          setIsAdmin(false);
          setError("Unable to verify admin status. Please contact support if you believe this is an error.");
        }
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleGoogleSignIn = async () => {
    try {
      setError(null);
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      
      try {
        const adminDoc = await getDoc(doc(db, "admins", user.uid));
        setIsAdmin(adminDoc.exists());
        if (!adminDoc.exists()) {
          setError("You do not have administrator access. Please contact support if you believe this is an error.");
        }
      } catch (error) {
        console.error("Error checking admin status:", error);
        setError("Unable to verify admin status. Please contact support if you believe this is an error.");
        setIsAdmin(false);
      }
    } catch (error) {
      console.error("Error signing in:", error);
      setError("Failed to sign in. Please try again.");
    }
  };

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      setIsAdmin(false);
      navigate("/admin");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const ProfileSection = ({ user }: { user: User }) => (
    <Box sx={{ mt: 3, mb: 3 }}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item>
          <Avatar
            src={user.photoURL || undefined}
            alt={user.displayName || "Admin"}
            sx={{ width: 80, height: 80 }}
          />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography variant="h6" sx={{ mt: 2 }}>
            {user.displayName}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {user.email}
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ my: 3 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2" color="textSecondary">
            Account Created
          </Typography>
          <Typography variant="body1">
            {new Date(user.metadata.creationTime!).toLocaleDateString()}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2" color="textSecondary">
            Last Sign In
          </Typography>
          <Typography variant="body1">
            {new Date(user.metadata.lastSignInTime!).toLocaleDateString()}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );

  const ProfileDialog = ({ open, onClose, user }: { open: boolean; onClose: () => void; user: User }) => (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Profile Information</DialogTitle>
      <DialogContent>
        <ProfileSection user={user} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button 
          onClick={handleSignOut} 
          variant="contained" 
          color="error"
        >
          Sign Out
        </Button>
      </DialogActions>
    </Dialog>
  );

  const ProductDialog = ({ open, onClose, onProductAdded }: { 
    open: boolean; 
    onClose: () => void;
    onProductAdded: () => void;
  }) => {
    const [newProduct, setNewProduct] = useState<Partial<Product>>({
      title: '',
      description: '',
      quantity: 0,
      price: 0,
      photos: [],
      videos: []
    });
    const [uploading, setUploading] = useState(false);
    const [photoFiles, setPhotoFiles] = useState<File[]>([]);
    const [videoFiles, setVideoFiles] = useState<File[]>([]);

    const handleFileUpload = async (files: File[], type: 'photos' | 'videos') => {
      const urls: string[] = [];
      
      for (const file of files) {
        try {
          // Log file information
          console.log('Uploading file:', {
            name: file.name,
            type: file.type,
            size: file.size
          });

          // Create metadata with content type
          const metadata = {
            contentType: file.type
          };

          const timestamp = Date.now();
          const fileName = `${timestamp}_${file.name}`;
          const storageRef = ref(storage, `products/${type}/${fileName}`);
          
          // Upload with metadata
          const uploadResult = await uploadBytes(storageRef, file, metadata);
          const url = await getDownloadURL(uploadResult.ref);
          
          console.log('Upload successful:', {
            url,
            contentType: metadata.contentType
          });
          
          urls.push(url);
        } catch (error) {
          console.error(`Error uploading file ${file.name}:`, error);
          throw error;
        }
      }
      
      return urls;
    };

    const handleSave = async () => {
      try {
        setUploading(true);

        if (photoFiles.length === 0) {
          alert('Please add at least one photo');
          return;
        }

        const photoUrls = await handleFileUpload(photoFiles, 'photos');
        const videoUrls = await handleFileUpload(videoFiles, 'videos');

        const productData = {
          ...newProduct,
          photos: photoUrls,
          videos: videoUrls,
          createdAt: new Date()
        };

        await addDoc(collection(db, "products"), productData);
        onProductAdded();
        handleClose();
      } catch (error) {
        console.error("Error saving product:", error);
        alert('Error saving product. Please try again.');
      } finally {
        setUploading(false);
      }
    };

    const handleClose = () => {
      setNewProduct({
        title: '',
        description: '',
        quantity: 0,
        price: 0,
        photos: [],
        videos: []
      });
      setPhotoFiles([]);
      setVideoFiles([]);
      onClose();
    };

    return (
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Add New Product</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
            <TextField
              label="Title"
              fullWidth
              value={newProduct.title}
              onChange={(e) => setNewProduct({ ...newProduct, title: e.target.value })}
            />
            <TextField
              label="Description"
              fullWidth
              multiline
              rows={4}
              value={newProduct.description}
              onChange={(e) => setNewProduct({ ...newProduct, description: e.target.value })}
            />
            <TextField
              label="Quantity"
              type="number"
              fullWidth
              value={newProduct.quantity}
              onChange={(e) => setNewProduct({ ...newProduct, quantity: parseInt(e.target.value) })}
            />
            <TextField
              label="Price"
              type="number"
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              value={newProduct.price}
              onChange={(e) => setNewProduct({ ...newProduct, price: parseFloat(e.target.value) })}
            />
            
            {/* Photo Upload */}
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Photos (at least one required)
              </Typography>
              <input
                accept="image/*"
                type="file"
                multiple
                onChange={(e) => {
                  const files = Array.from(e.target.files || []);
                  setPhotoFiles(files);
                }}
              />
              {photoFiles.length > 0 && (
                <Typography variant="caption">
                  Selected photos: {photoFiles.map(f => f.name).join(', ')}
                </Typography>
              )}
            </Box>

            {/* Video Upload */}
            {/* <Box>
              <Typography variant="subtitle1" gutterBottom>
                Videos (optional)
              </Typography>
              <input
                accept="video/*"
                type="file"
                multiple
                onChange={(e) => {
                  const files = Array.from(e.target.files || []);
                  setVideoFiles(files);
                }}
              />
              {videoFiles.length > 0 && (
                <Typography variant="caption">
                  Selected videos: {videoFiles.map(f => f.name).join(', ')}
                </Typography>
              )}
            </Box> */}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={uploading}>Cancel</Button>
          <Button 
            variant="contained" 
            onClick={handleSave}
            disabled={uploading || !newProduct.title || photoFiles.length === 0}
          >
            {uploading ? <CircularProgress size={24} /> : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const loadProducts = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "products"));
      const productsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Product[];
      setProducts(productsData);
    } catch (error) {
      console.error("Error loading products:", error);
    }
  };

  useEffect(() => {
    if (isAdmin) {
      loadProducts();
    }
  }, [isAdmin]);

  if (loading) {
    return (
      <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "80vh" }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      {/* Profile Icon */}
      {user && (
        <Box sx={{ 
          position: 'absolute', 
          top: 80,
          left: 16,
          zIndex: 1
        }}>
          <IconButton onClick={() => setProfileOpen(true)}>
            <Avatar
              src={user.photoURL || undefined}
              alt={user.displayName || "Admin"}
              sx={{ width: 40, height: 40 }}
            />
          </IconButton>
        </Box>
      )}

      <Paper elevation={3} sx={{ p: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
          <Typography variant="h4" component="h1">
            Admin Dashboard
          </Typography>
          {isAdmin && (
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setProductDialogOpen(true)}
            >
              Add Product
            </Button>
          )}
        </Box>

        {!user ? (
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="body1" sx={{ mb: 3 }}>
              Please sign in with your admin account
            </Typography>
            <Button
              variant="contained"
              startIcon={<GoogleIcon />}
              onClick={handleGoogleSignIn}
              size="large"
            >
              Sign in with Google
            </Button>
          </Box>
        ) : (
          <Box>
            {error && (
              <Typography 
                variant="body1" 
                color="error" 
                sx={{ mb: 3, textAlign: "center" }}
              >
                {error}
              </Typography>
            )}
            
            {isAdmin && (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Image</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products.map((product) => (
                      <TableRow key={product.id}>
                        <TableCell>
                          <img 
                            src={product.photos[0]} 
                            alt={product.title} 
                            style={{ width: 50, height: 50, objectFit: 'cover' }}
                          />
                        </TableCell>
                        <TableCell>{product.title}</TableCell>
                        <TableCell>₹{product.price.toFixed(2)}</TableCell>
                        <TableCell>{product.quantity}</TableCell>
                        <TableCell>
                          <IconButton>
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        )}
      </Paper>

      {user && (
        <>
          <ProfileDialog 
            open={profileOpen} 
            onClose={() => setProfileOpen(false)} 
            user={user} 
          />
          <ProductDialog 
            open={productDialogOpen} 
            onClose={() => setProductDialogOpen(false)}
            onProductAdded={loadProducts}
          />
        </>
      )}
    </Container>
  );
};

export default AdminPage; 