import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  IconButton, 
  Badge,
  Box
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useNavigate } from "react-router-dom";
import ProductsPage from "./pages/ProductsPage";
import AdminPage from "./pages/AdminPage";
import ProductPage from './pages/ProductPage';
import { getCartItemsCount } from './utils/cartUtils';
import CartPage from './pages/CartPage';

function AppWrapper() {
  const navigate = useNavigate();
  const [cartCount, setCartCount] = useState(getCartItemsCount());

  useEffect(() => {
    const handleCartUpdate = () => {
      setCartCount(getCartItemsCount());
    };

    window.addEventListener('cartUpdated', handleCartUpdate);
    return () => window.removeEventListener('cartUpdated', handleCartUpdate);
  }, []);

  return (
    <div className="App">
      <AppBar position="sticky" color="default" elevation={0}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <IconButton 
            color="inherit" 
            onClick={() => navigate('/')}
            edge="start"
          >
            <HomeIcon />
          </IconButton>
          
          <Typography variant="h6" component="div">
            Emu Store
          </Typography>

          <IconButton color="inherit" onClick={() => navigate('/cart')}>
            <Badge badgeContent={cartCount} color="error">
              <ShoppingCartIcon />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box sx={{ pt: 2 }}>
        <Routes>
          <Route path="/" element={<ProductsPage />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/product/:productId" element={<ProductPage />} />
          <Route path="/cart" element={<CartPage />} />
        </Routes>
      </Box>
    </div>
  );
}

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#7FB3D5",
      },
      secondary: {
        main: "#F5B7B1",
      },
    },
    shape: {
      borderRadius: 8,
    },
    typography: {
      fontFamily: "Roboto, sans-serif",
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AppWrapper />
      </Router>
    </ThemeProvider>
  );
}

export default App;
