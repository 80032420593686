import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyDswov7W1ktjLJUEl32vlQ9InhJxRBDpU4",
    authDomain: "emu-store.firebaseapp.com",
    projectId: "emu-store",
    storageBucket: "emu-store.firebasestorage.app",
    messagingSenderId: "588131795297",
    appId: "1:588131795297:web:f08bd52902863d65d6c520",
    measurementId: "G-KDL9L7JC07"
  };
  
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const googleProvider = new GoogleAuthProvider(); 